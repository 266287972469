/* modified by ui-hub */
import loadable from "@loadable/component";

const registry = {
  BankLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icon.svg"))
        .ReactComponent
  ),
  Exit: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/exit-icon.svg"))
        .ReactComponent
  ),
  GCLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/gocardless-logo.svg"))
        .ReactComponent
  ),
  GCRebrandLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/gocardless-rebrand-logo.svg"
        )
      ).ReactComponent
  ),
  GCBank: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/gocardless-bank.svg"))
        .ReactComponent
  ),
  DDLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/directdebit-logo.svg"))
        .ReactComponent
  ),
  SepaLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/sepa.svg")).ReactComponent
  ),
  PayToLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/payto-logo.svg"))
        .ReactComponent
  ),
  PayIDLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/payid-logo.svg"))
        .ReactComponent
  ),
  RevolutLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/revolut.svg"))
        .ReactComponent
  ),
  ExclamationMark: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/exclamation-mark.svg"))
        .ReactComponent
  ),
  ShieldOld: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/shield-old.svg"))
        .ReactComponent
  ),
  Shield: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/shield.svg"))
        .ReactComponent
  ),
  Tick: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/tick.svg")).ReactComponent
  ),
  BulbLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bulb.svg")).ReactComponent
  ),
  GovernmentLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/government.svg"))
        .ReactComponent
  ),
  TheGuardianLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/the-guardian.svg"))
        .ReactComponent
  ),
  TripAdvisorLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/trip-advisor.svg"))
        .ReactComponent
  ),
  BankIdLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bankid-logo.svg"))
        .ReactComponent
  ),
  WarningLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/warning.svg"))
        .ReactComponent
  ),

  // bank logos
  BarclaysLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/barclays.svg"))
        .ReactComponent
  ),
  CoOperativeLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/co-operative.svg"
        )
      ).ReactComponent
  ),
  CouttsLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/coutts.svg"))
        .ReactComponent
  ),
  FirstdirectLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/firstdirect.svg"
        )
      ).ReactComponent
  ),
  HalifaxLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/halifax.svg"))
        .ReactComponent
  ),
  HSBCLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/hsbc.svg"))
        .ReactComponent
  ),
  LloydsLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/lloyds.svg"))
        .ReactComponent
  ),
  MetroBankLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/metro-bank.svg"
        )
      ).ReactComponent
  ),
  MonzoLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/monzo.svg"))
        .ReactComponent
  ),
  NationalWestminsterLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/national-westminster.svg"
        )
      ).ReactComponent
  ),
  NationwideLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/nationwide.svg"
        )
      ).ReactComponent
  ),
  RBSLogo: loadable(
    async () =>
      (
        await import(
          __dirname +
            "/../../../assets/svg/bank-icons/royal-bank-of-scotland.svg"
        )
      ).ReactComponent
  ),
  SantanderLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/santander.svg"
        )
      ).ReactComponent
  ),
  StarlingLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/starling.svg"))
        .ReactComponent
  ),
  TSBLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/tsb.svg"))
        .ReactComponent
  ),
  YorkshireLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/yorkshire.svg"
        )
      ).ReactComponent
  ),
  NatwestLogo: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-icons/natwest.svg"))
        .ReactComponent
  ),
  BankOfScotlandLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/bank-of-scotland.svg"
        )
      ).ReactComponent
  ),
  DanskeBankLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/danskebank.svg"
        )
      ).ReactComponent
  ),
  VirginMoneyLogo: loadable(
    async () =>
      (
        await import(
          __dirname + "/../../../assets/svg/bank-icons/virginmoney.svg"
        )
      ).ReactComponent
  ),
  ContrastSpinner: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/spinner_contrast.svg"))
        .ReactComponent
  ),
  BankIdLogoSmall: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bankid-logo-small.svg"))
        .ReactComponent
  ),
  ExclamationMarkCircle: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/exclamation-circle.svg"))
        .ReactComponent
  ),
  GetStartedPlanes: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/get-started-planes.svg"))
        .ReactComponent
  ),
  BankLogos: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/bank-logos.svg"))
        .ReactComponent
  ),

  // Icons
  IconsBank: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/bank.svg"))
        .ReactComponent
  ),
  IconsCard: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/card.svg"))
        .ReactComponent
  ),
  IconsBolt: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/bolt.svg"))
        .ReactComponent
  ),
  IconsLock: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/lock.svg"))
        .ReactComponent
  ),
  IconsMobile: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/mobile.svg"))
        .ReactComponent
  ),
  IconsPlane: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/plane.svg"))
        .ReactComponent
  ),
  IconsQrcode: loadable(
    async () =>
      (await import(__dirname + "/../../../assets/svg/icons/qrcode.svg"))
        .ReactComponent
  ),
};

export default registry;
