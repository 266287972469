import { useContext } from "react";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
  P,
  ButtonLayout,
  TypePreset,
} from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  retryPaymentFlow,
} from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { Trans } from "@lingui/macro";

export enum DataTestIds {
  ErrorHeading = "error-heading-bank-auth",
  TryAgainButton = "try-again-button-bank-auth",
}

const InsufficientFundsError = () => {
  const {
    setError,
    payerTheme,
    billingRequest,
    setBankAuthorisation,
    setShouldCreateNewBankAuth,
    runtimeMode,
  } = useContext(GlobalState);

  return (
    <>
      <Box justifyContent={JustifyContent.Center}>
        <P preset={TypePreset.Heading_06} spaceBelow={1}>
          <Trans id="bank-authorisation-error.insufficient-funds.error-message">
            Your account has insufficient funds
          </Trans>
        </P>
      </Box>
      <>
        <P
          preset={TypePreset.Body_02}
          spaceBelow={2}
          data-testid={DataTestIds.ErrorHeading}
        >
          <Trans id="bank-authorisation-error.insufficient-funds.restart-message-1">
            The account you&apos;re trying to pay with does not have enough
            money to make this payment.
          </Trans>
          <br />
          <br />
          <Trans id="bank-authorisation-error.insufficient-funds.restart-message-2">
            Either add more funds to this bank account and try again, or select
            a different bank.
          </Trans>
        </P>
        <Box spaceBelow={1}>
          <BrandedButton
            backgroundColor={getBrandColorFor(
              BrandedComponentType.Button,
              payerTheme
            )}
            variant={ButtonVariant.PrimaryOnLight}
            onClick={async () => {
              setShouldCreateNewBankAuth(true, async () => {
                await retryPaymentFlow(billingRequest, runtimeMode);
                setBankAuthorisation(null, () => {
                  setError(undefined);
                });
              });
            }}
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            data-testid={DataTestIds.TryAgainButton}
          >
            <Trans id="bank-authorisation-error.insufficient-funds.restart-button">
              Select a bank account
            </Trans>
          </BrandedButton>
        </Box>
      </>
    </>
  );
};

export default InsufficientFundsError;
