import { PaymentVariant } from "./payments";

// Milliseconds
const SECOND = 1000;
const MINUTE = 60 * SECOND;
export const SEGMENT_REDIRECT_TIMEOUT_MS = SECOND / 2;
export const BANK_AUTH_POLLING_INTERVAL = 2 * SECOND;
export const TIMEOUT_PERIOD = 5 * MINUTE;
export const REDIRECT_WAIT_PERIOD = 4 * SECOND;
export const POST_AUTH_MAX_WAIT_PERIOD = 5 * SECOND;
export const POST_AUTH_MIN_WAIT_PERIOD = 2 * SECOND;
export const TMX_TRACKING_WAIT_PERIOD = 3 * SECOND;
export const GC_SUPPORT_EMAIL = "help@gocardless.com";

export const DEFAULT_COUNTRY_CODE = "GB";
export const MIN_MATCHED_INSTITUTION_LIMIT = 5;
export const MAX_INSTITUTIONS_TO_SHOW = 14;
export const MIN_INSTITUTION_FILTER_LENGTH = 2;
export const BRF_E_VARIANT_EXPERIMENT = "brf_e_variant_experiment";
export const INSTANT_PAY_VARIANTS = [
  PaymentVariant.InstantBankPay,
  PaymentVariant.DualFlow,
  PaymentVariant.VariableRecurringPaymentsWithFirstPayment,
];
export const INSTITUTION_ANDROID_PACKAGE_NAME: { [index: string]: string } = {
  AIB_FTBKGB2BXXX: "ftb.ibank.android",
  ARBUTHNOT_LATHAM_ARBUGB2LXXX: "au.com.sandstone.db.alb",
  BANK_OF_IRELAND_B365_BOFIGB2B: "com.bankofireland.mobilebanking",
  BANK_OF_IRELAND_BUSINESS_ONLINE_BOFIGB2B: "com.bankofireland.mobilebanking",
  BANK_OF_SCOTLAND_BUSINESS_BOFSGBS1: "uk.co.bankofscotland.businessbank",
  BANK_OF_SCOTLAND_COMMERCIAL_BOFSGBS1: "uk.co.bankofscotland.businessbank",
  BANK_OF_SCOTLAND_BOFSGBS1: "com.grppl.android.shell.BOS",
  BARCLAYCARD_BUKBGB22: "com.barclays.bca",
  BARCLAYS_BUSINESS_BUKBGB22: "com.barclays.android.barclaysmobilebanking",
  BARCLAYS_BUKBGB22: "com.barclays.android.barclaysmobilebanking",
  BARCLAYS_WEALTH_BUKBGB22: "com.barclays.android.barclaysmobilebanking",
  CHASE_CHASGB2L: "com.chase.intl",
  COUTTS_COUTGB22XXX: "com.coutts.model.prod.tadpole",
  DANSKEBANK_BUSINESS_DABAGB2B: "com.danskebank.mobilebank3.uk",
  DANSKEBANK_DABAGB2B: "com.danskebank.mobilebank3.uk",
  FIRST_DIRECT_MIDLGB22: "com.firstdirect.bankingonthego",
  HSBC_BUSINESS_HBUKGB4B: "uk.co.hsbc.hsbcukbusinessbanking",
  HSBC_KINETIC_HBUKGB4B: "com.hsbc.kinetic",
  HSBC_HBUKGB4B: "uk.co.hsbc.hsbcukmobilebanking",
  HSBC_NET_HBUKGB4B: "com.hsbc.hsbcnet",
  HALIFAX_HLFXGB22: "com.grppl.android.shell.halifax",
  LLOYDS_BUSINESS_LOYDGB2L: "com.lloydsbank.businessmobile",
  LLOYDS_COMMERCIAL_LOYDGB2L: "com.lloydsbank.businessmobile",
  LLOYDS_LOYDGB2L: "com.grppl.android.shell.CMBlloydsTSB73",
  MONZO_MONZGB2L: "co.uk.getmondo",
  NATIONWIDE_NAIAGB21: "co.uk.Nationwide.Mobile",
  NATWEST_NWBKGB2L: "com.rbs.mobile.android.natwest",
  NATWEST_CORP_NWBKGB2L: "com.rbs.banklinemobile.natwest",
  NATWEST_INTERNATONAL_RBOSGIGI: "com.rbs.mobile.android.natwestoffshore",
  REVOLUT_REVOGB21: "com.revolut.revolut",
  RBS_GB_RBSSGBKC: "com.rbs.mobile.android.rbs",
  RBS_GB_CORP_RBSSGBKC: "com.rbs.banklinemobile.rbs",
  SANTANDER_GB_ABBYGB2L: "uk.co.santander.santanderUK",
  STARLING_SRLGGB3L: "com.starlingbank.android",
  TSB_GB_TSBSGB2A: "uk.co.tsb.newmobilebank",
  VIRGIN_NRNBGB22: "com.virginmoney.uk.mobile.android",
  WISE_TRWIGB22: "com.transferwise.android",
  YBS_YORBGB2V: "uk.co.ybs.savings.external",
};
