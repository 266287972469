import { InstitutionResource } from "@gocardless/api/dashboard/types";
import {
  Color,
  ColorProp,
  CSSRulesFunction,
  Theme,
} from "@gocardless/flux-react";
import { isMobile } from "react-device-detect";

const scrollHeight = isMobile ? 380 : 430;

export const listStyle: CSSRulesFunction<{
  institutions: InstitutionResource[] | undefined;
  backgroundColor?: string;
}> = (theme: Theme, props) => {
  const { institutions, backgroundColor } = props;
  return {
    height: institutions && institutions.length <= 4 ? "auto" : scrollHeight,
    overflow: "auto",
    overflowY: "auto",
    backgroundColor,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    WebkitOverflowScrolling: "touch",
    position: "relative",
    zIndex: 1,
    overscrollBehavior: "contain",
  };
};

export const scrollOverlayStyle: CSSRulesFunction<{
  color?: ColorProp;
}> = (theme: Theme, props) => ({
  position: "absolute",
  bottom: -8,
  left: 0,
  right: 0,
  pointerEvents: "none",
  zIndex: 2,
  background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${theme.color(props?.color || Color.White)} 88.45%)`,
});
