import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

export enum CountryCodes {
  AD = "AD",
  AT = "AT",
  AU = "AU",
  AX = "AX",
  BE = "BE",
  BG = "BG",
  BL = "BL",
  CA = "CA",
  CH = "CH",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DK = "DK",
  EE = "EE",
  ES = "ES",
  FI = "FI",
  LI = "LI",
  FO = "FO",
  FR = "FR",
  GB = "GB",
  GF = "GF",
  GG = "GG",
  GL = "GL",
  GP = "GP",
  GR = "GR",
  HR = "HR",
  HU = "HU",
  IE = "IE",
  IM = "IM",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  MC = "MC",
  MF = "MF",
  MQ = "MQ",
  MT = "MT",
  NL = "NL",
  NO = "NO",
  NZ = "NZ",
  PL = "PL",
  PM = "PM",
  PT = "PT",
  RE = "RE",
  RO = "RO",
  SE = "SE",
  SI = "SI",
  SK = "SK",
  SM = "SM",
  US = "US",
  VA = "VA",
  YT = "YT",
}

export enum USRegion {
  Alabama = "AL",
  Alaska = "AK",
  Arizona = "AZ",
  Arkansas = "AR",
  California = "CA",
  Colorado = "CO",
  Connecticut = "CT",
  Delaware = "DE",
  DistrictOfColumbia = "DC",
  Florida = "FL",
  Georgia = "GA",
  Guam = "GU",
  Hawaii = "HI",
  Idaho = "ID",
  Illinois = "IL",
  Indiana = "IN",
  Iowa = "IA",
  Kansas = "KS",
  Kentucky = "KY",
  Louisiana = "LA",
  Maine = "ME",
  Maryland = "MD",
  Massachusetts = "MA",
  Michigan = "MI",
  Minnesota = "MN",
  Mississippi = "MS",
  Missouri = "MO",
  Montana = "MT",
  Nebraska = "NE",
  Nevada = "NV",
  NewHampshire = "NH",
  NewJersey = "NJ",
  NewMexico = "NM",
  NewYork = "NY",
  NorthCarolina = "NC",
  NorthDakota = "ND",
  Ohio = "OH",
  Oklahoma = "OK",
  Oregon = "OR",
  Pennsylvania = "PA",
  PuertoRico = "PR",
  RhodeIsland = "RI",
  SouthCarolina = "SC",
  SouthDakota = "SD",
  Tennessee = "TN",
  Texas = "TX",
  Utah = "UT",
  Vermont = "VT",
  VirginIslands = "VI",
  Virginia = "VA",
  Washington = "WA",
  WestVirginia = "WV",
  Wisconsin = "WI",
  Wyoming = "WY",
}

export const countryCodeToName = (
  i18n: I18n
): Record<CountryCodes, string> => ({
  [CountryCodes.AD]: i18n._(
    t({ id: "country_name.andorra", message: "Andorra" })
  ),
  [CountryCodes.AT]: i18n._(
    t({ id: "country_name.austria", message: "Austria" })
  ),
  [CountryCodes.AU]: i18n._(
    t({ id: "country_name.australia", message: "Australia" })
  ),
  [CountryCodes.AX]: i18n._(
    t({
      id: "country_name.aland_islands",
      message: "Åland Islands",
    })
  ),
  [CountryCodes.BE]: i18n._(
    t({ id: "country_name.belgium", message: "Belgium" })
  ),
  [CountryCodes.BG]: i18n._(
    t({ id: "country_name.bulgaria", message: "Bulgaria" })
  ),
  [CountryCodes.BL]: i18n._(
    t({
      id: "country_name.saint_barthelemy",
      message: "Saint Barthélemy",
    })
  ),
  [CountryCodes.CA]: i18n._(
    t({ id: "country_name.canada", message: "Canada" })
  ),
  [CountryCodes.CH]: i18n._(
    t({ id: "country_name.switzerland", message: "Switzerland" })
  ),
  [CountryCodes.CY]: i18n._(
    t({ id: "country_name.cyprus", message: "Cyprus" })
  ),
  [CountryCodes.CZ]: i18n._(
    t({ id: "country_name.czech_republic", message: "Czech Republic" })
  ),
  [CountryCodes.DE]: i18n._(
    t({ id: "country_name.germany", message: "Germany" })
  ),
  [CountryCodes.DK]: i18n._(
    t({ id: "country_name.denmark", message: "Denmark" })
  ),
  [CountryCodes.EE]: i18n._(
    t({ id: "country_name.estonia", message: "Estonia" })
  ),
  [CountryCodes.ES]: i18n._(t({ id: "country_name.spain", message: "Spain" })),
  [CountryCodes.FI]: i18n._(
    t({ id: "country_name.finland", message: "Finland" })
  ),
  [CountryCodes.LI]: i18n._(
    t({ id: "country_name.liechtenstein", message: "Liechtenstein" })
  ),
  [CountryCodes.FO]: i18n._(
    t({ id: "country_name.faroe_islands", message: "Faroe Islands" })
  ),
  [CountryCodes.FR]: i18n._(
    t({ id: "country_name.france", message: "France" })
  ),
  [CountryCodes.GB]: i18n._(
    t({
      id: "country_name.united_kingdom",
      message: "United Kingdom",
    })
  ),
  [CountryCodes.GF]: i18n._(
    t({
      id: "country_name.french_guiana",
      message: "French Guiana",
    })
  ),
  [CountryCodes.GL]: i18n._(
    t({
      id: "country_name.greenland",
      message: "Greenland",
    })
  ),
  [CountryCodes.GP]: i18n._(
    t({
      id: "country_name.guadeloupe",
      message: "Guadeloupe",
    })
  ),
  [CountryCodes.GR]: i18n._(
    t({ id: "country_name.greece", message: "Greece" })
  ),
  [CountryCodes.GG]: i18n._(
    t({ id: "country_name.guernsey", message: "Guernsey" })
  ),
  [CountryCodes.HR]: i18n._(
    t({ id: "country_name.croatia", message: "Croatia" })
  ),
  [CountryCodes.HU]: i18n._(
    t({ id: "country_name.hungary", message: "Hungary" })
  ),
  [CountryCodes.IE]: i18n._(
    t({ id: "country_name.ireland", message: "Ireland" })
  ),
  [CountryCodes.IM]: i18n._(
    t({ id: "country_name.isle_of_man", message: "Isle of Man" })
  ),
  [CountryCodes.IS]: i18n._(
    t({ id: "country_name.iceland", message: "Iceland" })
  ),
  [CountryCodes.IT]: i18n._(t({ id: "country_name.italy", message: "Italy" })),
  [CountryCodes.JE]: i18n._(
    t({ id: "country_name.jersey", message: "Jersey" })
  ),
  [CountryCodes.LT]: i18n._(
    t({ id: "country_name.lithuania", message: "Lithuania" })
  ),
  [CountryCodes.LU]: i18n._(
    t({
      id: "country_name.luxembourg",
      message: "Luxembourg",
    })
  ),
  [CountryCodes.LV]: i18n._(
    t({ id: "country_name.latvia", message: "Latvia" })
  ),
  [CountryCodes.MC]: i18n._(
    t({ id: "country_name.monaco", message: "Monaco" })
  ),
  [CountryCodes.MF]: i18n._(
    t({
      id: "country_name.saint_martin",
      message: "Saint Martin",
    })
  ),
  [CountryCodes.MQ]: i18n._(
    t({
      id: "country_name.martinique",
      message: "Martinique",
    })
  ),
  [CountryCodes.MT]: i18n._(t({ id: "country_name.malta", message: "Malta" })),
  [CountryCodes.NO]: i18n._(
    t({ id: "country_name.norway", message: "Norway" })
  ),
  [CountryCodes.NL]: i18n._(
    t({
      id: "country_name.netherlands",
      message: "Netherlands",
    })
  ),
  [CountryCodes.NZ]: i18n._(
    t({
      id: "country_name.new_zealand",
      message: "New Zealand",
    })
  ),
  [CountryCodes.PL]: i18n._(
    t({ id: "country_name.poland", message: "Poland" })
  ),
  [CountryCodes.PM]: i18n._(
    t({
      id: "country_name.saint_pierre_and_miquelon",
      message: "Saint Pierre and Miquelon",
    })
  ),
  [CountryCodes.PT]: i18n._(
    t({ id: "country_name.portugal", message: "Portugal" })
  ),
  [CountryCodes.RE]: i18n._(
    t({ id: "country_name.reunion", message: "Réunion" })
  ),
  [CountryCodes.RO]: i18n._(
    t({ id: "country_name.romania", message: "Romania" })
  ),
  [CountryCodes.SE]: i18n._(
    t({ id: "country_name.sweden", message: "Sweden" })
  ),
  [CountryCodes.SI]: i18n._(
    t({ id: "country_name.slovenia", message: "Slovenia" })
  ),
  [CountryCodes.SK]: i18n._(
    t({ id: "country_name.slovakia", message: "Slovakia" })
  ),
  [CountryCodes.SM]: i18n._(
    t({
      id: "country_name.san_marino",
      message: "San Marino",
    })
  ),
  [CountryCodes.US]: i18n._(
    t({
      id: "country_name.united_states",
      message: "United States",
    })
  ),
  [CountryCodes.VA]: i18n._(
    t({ id: "country_name.vatican_city_state", message: "Vatican City State" })
  ),
  [CountryCodes.YT]: i18n._(
    t({ id: "country_name.mayotte", message: "Mayotte" })
  ),
});

export const usRegionNames = (i18n: I18n): Record<USRegion, string> => ({
  [USRegion.Alabama]: i18n._(
    t({ id: "region.us.alabama", message: "Alabama" })
  ),
  [USRegion.Alaska]: i18n._(t({ id: "region.us.alaska", message: "Alaska" })),
  [USRegion.Arizona]: i18n._(
    t({ id: "region.us.arizona", message: "Arizona" })
  ),
  [USRegion.Arkansas]: i18n._(
    t({ id: "region.us.arkansas", message: "Arkansas" })
  ),
  [USRegion.California]: i18n._(
    t({ id: "region.us.california", message: "California" })
  ),
  [USRegion.Colorado]: i18n._(
    t({ id: "region.us.colorado", message: "Colorado" })
  ),
  [USRegion.Connecticut]: i18n._(
    t({
      id: "region.us.connecticut",
      message: "Connecticut",
    })
  ),
  [USRegion.Delaware]: i18n._(
    t({ id: "region.us.delaware", message: "Delaware" })
  ),
  [USRegion.DistrictOfColumbia]: i18n._(
    t({
      id: "region.us.district-of-columbia",
      message: "District Of Columbia",
    })
  ),
  [USRegion.Florida]: i18n._(
    t({ id: "region.us.florida", message: "Florida" })
  ),
  [USRegion.Georgia]: i18n._(
    t({ id: "region.us.georgia", message: "Georgia" })
  ),
  [USRegion.Guam]: i18n._(t({ id: "region.us.guam", message: "Guam" })),
  [USRegion.Hawaii]: i18n._(t({ id: "region.us.hawaii", message: "Hawaii" })),
  [USRegion.Idaho]: i18n._(t({ id: "region.us.idaho", message: "Idaho" })),
  [USRegion.Illinois]: i18n._(
    t({ id: "region.us.illinois", message: "Illinois" })
  ),
  [USRegion.Indiana]: i18n._(
    t({ id: "region.us.indiana", message: "Indiana" })
  ),
  [USRegion.Iowa]: i18n._(t({ id: "region.us.iowa", message: "Iowa" })),
  [USRegion.Kansas]: i18n._(t({ id: "region.us.kansas", message: "Kansas" })),
  [USRegion.Kentucky]: i18n._(
    t({ id: "region.us.kentucky", message: "Kentucky" })
  ),
  [USRegion.Louisiana]: i18n._(
    t({ id: "region.us.louisiana", message: "Louisiana" })
  ),
  [USRegion.Maine]: i18n._(t({ id: "region.us.maine", message: "Maine" })),
  [USRegion.Maryland]: i18n._(
    t({ id: "region.us.maryland", message: "Maryland" })
  ),
  [USRegion.Massachusetts]: i18n._(
    t({
      id: "region.us.massachusetts",
      message: "Massachusetts",
    })
  ),
  [USRegion.Michigan]: i18n._(
    t({ id: "region.us.michigan", message: "Michigan" })
  ),
  [USRegion.Minnesota]: i18n._(
    t({ id: "region.us.minnesota", message: "Minnesota" })
  ),
  [USRegion.Mississippi]: i18n._(
    t({
      id: "region.us.mississippi",
      message: "Mississippi",
    })
  ),
  [USRegion.Missouri]: i18n._(
    t({ id: "region.us.missouri", message: "Missouri" })
  ),
  [USRegion.Montana]: i18n._(
    t({ id: "region.us.montana", message: "Montana" })
  ),
  [USRegion.Nebraska]: i18n._(
    t({ id: "region.us.nebraska", message: "Nebraska" })
  ),
  [USRegion.Nevada]: i18n._(t({ id: "region.us.nevada", message: "Nevada" })),
  [USRegion.NewHampshire]: i18n._(
    t({
      id: "region.us.new-hampshire",
      message: "New Hampshire",
    })
  ),
  [USRegion.NewJersey]: i18n._(
    t({ id: "region.us.new-jersey", message: "New Jersey" })
  ),
  [USRegion.NewMexico]: i18n._(
    t({ id: "region.us.new-mexico", message: "New Mexico" })
  ),
  [USRegion.NewYork]: i18n._(
    t({ id: "region.us.new-york", message: "New York" })
  ),
  [USRegion.NorthCarolina]: i18n._(
    t({
      id: "region.us.north-carolina",
      message: "North Carolina",
    })
  ),
  [USRegion.NorthDakota]: i18n._(
    t({
      id: "region.us.north-dakota",
      message: "North Dakota",
    })
  ),
  [USRegion.Ohio]: i18n._(t({ id: "region.us.ohio", message: "Ohio" })),
  [USRegion.Oklahoma]: i18n._(
    t({ id: "region.us.oklahoma", message: "Oklahoma" })
  ),
  [USRegion.Oregon]: i18n._(t({ id: "region.us.oregon", message: "Oregon" })),
  [USRegion.Pennsylvania]: i18n._(
    t({
      id: "region.us.pennsylvania",
      message: "Pennsylvania",
    })
  ),
  [USRegion.PuertoRico]: i18n._(
    t({
      id: "region.us.puerto-rico",
      message: "Puerto Rico",
    })
  ),
  [USRegion.RhodeIsland]: i18n._(
    t({
      id: "region.us.rhode-island",
      message: "Rhode Island",
    })
  ),
  [USRegion.SouthCarolina]: i18n._(
    t({
      id: "region.us.south-carolina",
      message: "South Carolina",
    })
  ),
  [USRegion.SouthDakota]: i18n._(
    t({
      id: "region.us.south-dakota",
      message: "South Dakota",
    })
  ),
  [USRegion.Tennessee]: i18n._(
    t({ id: "region.us.tennessee", message: "Tennessee" })
  ),
  [USRegion.Texas]: i18n._(t({ id: "region.us.texas", message: "Texas" })),
  [USRegion.Utah]: i18n._(t({ id: "region.us.utah", message: "Utah" })),
  [USRegion.Vermont]: i18n._(
    t({ id: "region.us.vermont", message: "Vermont" })
  ),
  [USRegion.VirginIslands]: i18n._(
    t({
      id: "region.us.virgin-islands",
      message: "Virgin Islands",
    })
  ),
  [USRegion.Virginia]: i18n._(
    t({ id: "region.us.virginia", message: "Virginia" })
  ),
  [USRegion.Washington]: i18n._(
    t({ id: "region.us.washington", message: "Washington" })
  ),
  [USRegion.WestVirginia]: i18n._(
    t({
      id: "region.us.west-virginia",
      message: "West Virginia",
    })
  ),
  [USRegion.Wisconsin]: i18n._(
    t({ id: "region.us.wisconsin", message: "Wisconsin" })
  ),
  [USRegion.Wyoming]: i18n._(
    t({ id: "region.us.wyoming", message: "Wyoming" })
  ),
});
