import {
  BillingRequestResource,
  InstitutionResource,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Button,
  ButtonLayout,
  ButtonVariant,
  Color,
  Glyph,
  Icon,
  Interpose,
  LI,
  Space,
  SpaceScale,
  useTheme,
} from "@gocardless/flux-react";
import { useContext, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { isRole } from "src/common/config";
import { Role } from "src/common/environments";
import { isEligibleForIBPv2, isSEPASchemeMandateOnly } from "src/common/utils";
import InstitutionWithIcon from "src/components/shared/InstitutionWithIcon";
import { GlobalState } from "src/state";

import { boxButtonStyle as boxButtonStyleV2 } from "../../../shared/V2/InstitutionSelection/institutionLink.style";

import { boxButtonStyle } from "./institutionLink.style";

export interface InstitutionLinkProps {
  institution: InstitutionResource;
  onSelect: (institution: InstitutionResource) => void;
  hSpaceScale?: SpaceScale;
}
const InstitutionLink = ({
  institution,
  onSelect,
  hSpaceScale = 1.5,
}: InstitutionLinkProps) => {
  const { theme } = useTheme();
  const isSandbox = isRole(Role.sandbox);
  const { billingRequest, runtimeMode } = useContext(GlobalState);

  const isSepaMandateFlow = isSEPASchemeMandateOnly(
    billingRequest as BillingRequestResource
  );

  const isIBPv2 = useMemo(
    () => isEligibleForIBPv2(billingRequest, runtimeMode),
    [billingRequest, runtimeMode]
  );

  const buttonStyle = useMemo(
    () => (isIBPv2 ? boxButtonStyleV2 : boxButtonStyle),
    [isIBPv2]
  );

  return (
    <LI>
      <Button
        onClick={() => onSelect(institution)}
        data-testid={institution.id}
        layout={ButtonLayout.Full}
        variant={ButtonVariant.TextOnLight}
        css={buttonStyle(theme, {
          hoverBackgroundColor: isSepaMandateFlow
            ? theme.color(Color.White)
            : theme.color(Color.Greystone_100),
        })}
        className="fs-unmask"
      >
        <Box
          layout="flex"
          flexWrap="nowrap"
          alignItems={AlignItems.Center}
          spaceAbove={isMobile ? 0.5 : 0}
          spaceBelow={isMobile ? 0.5 : 0}
        >
          <Interpose node={<Space h={1} layout="inline" />}>
            <Box flexGrow={1}>
              <InstitutionWithIcon
                institution={institution}
                hSpaceScale={hSpaceScale}
                iconHeight={isIBPv2 ? "32px" : undefined}
                iconWidth={isIBPv2 ? "32px" : undefined}
              />
            </Box>
            {!isSandbox && <Icon name={Glyph.ArrowForward} />}
          </Interpose>
        </Box>
      </Button>
    </LI>
  );
};

export default InstitutionLink;
