import { useInstitutionListForBillingRequest } from "@gocardless/api/dashboard/institution";
import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  InstitutionResource,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  Color,
  FontWeight,
  Glyph,
  Icon,
  Input,
  Layer,
  P,
  Space,
  Text,
  ZIndex,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Fuse from "fuse.js";
import debounce from "lodash/debounce";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { BankDE, BankGB, BankUS, getBanksWithInitials } from "src/common/banks";
import { isRole } from "src/common/config";
import { MIN_INSTITUTION_FILTER_LENGTH } from "src/common/consts";
import { CountryCodes } from "src/common/country";
import { Role } from "src/common/environments";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import {
  TopPopularBusinessBanks,
  TopPopularPersonalBanks,
} from "src/common/topPopularBanks";
import { TrackingEvents } from "src/common/trackingEvents";
import {
  isEligibleForIBPv2,
  isSEPASchemeMandateOnly,
  isUsingCompanyName,
  leadBillingRequestScheme,
  mandateSchemeIs,
  usesOpenBankingGatewayAisAdapter,
} from "src/common/utils";
import { useSegment } from "src/shared/Segment/useSegment";
import { GlobalState } from "src/state";

import EmptyState from "./EmptyState";
import InstitutionLinks from "./InstitutionLinks";
import SearchingState from "./SearchingState";

export interface InstitutionSelectionProps {
  billingRequest: BillingRequestResource;
  availableInstitutions: InstitutionResource[];
  bankAccountCountryCode?: string;
  onSelect: (institution: InstitutionResource) => void;
  isWithinDialog?: boolean;
}

// configuration for fuzzy matching search
const FUSE_CONFIG = {
  keys: [
    { name: "initials", weight: 0.7 },
    { name: "name", weight: 0.3 },
  ],
  minMatchCharLength: 1,
  threshold: 0.4,
  shouldSort: true,
};

const InstitutionSelection = ({
  billingRequest,
  availableInstitutions,
  bankAccountCountryCode,
  onSelect,
  isWithinDialog = false,
}: InstitutionSelectionProps) => {
  const { runtimeMode } = useContext(GlobalState);

  const [institutionFilter, setInstitutionFilter] = useState<string>("");

  const { sendEvent } = useSegment();

  const isSandbox = isRole(Role.sandbox);

  // Initialising fuse with empty list so as not to slow down initial load.
  // Search index is added as part of a hook later on.
  const [fuse, setFuse] = useState<Fuse<InstitutionResource>>(
    new Fuse([], FUSE_CONFIG)
  );

  const searchBarRef = useRef<HTMLInputElement | null>(null);

  const isIBPv2 = useMemo(
    () => isEligibleForIBPv2(billingRequest, runtimeMode),
    [billingRequest, runtimeMode]
  );

  // We are momoizing the generation the initials of the institution for each bank.
  // We use this as part of the search index to allow searching by initials
  // in addition to the full name
  const institutionsWithInitials = useMemo(() => {
    return availableInstitutions && availableInstitutions?.length > 0
      ? getBanksWithInitials(availableInstitutions)
      : [];
  }, [availableInstitutions]);

  useEffect(() => {
    if (!isIBPv2) {
      searchBarRef.current !== null && searchBarRef.current.focus();
    }
  }, [isIBPv2]);

  // Rebuild the search index if list of available institutions changes
  useEffect(() => {
    setFuse(new Fuse(institutionsWithInitials, FUSE_CONFIG));
  }, [institutionsWithInitials]);

  const isSepaMandateFlow = isSEPASchemeMandateOnly(billingRequest);

  const leadScheme = leadBillingRequestScheme(billingRequest) as Scheme;

  const shouldFetchInstitutionsFromAPI = useMemo(
    () =>
      Boolean(
        billingRequest.id &&
          bankAccountCountryCode &&
          (isSepaMandateFlow ||
            mandateSchemeIs(billingRequest, AvailableDebitSchemeEnum.Ach)) &&
          usesOpenBankingGatewayAisAdapter(billingRequest) &&
          institutionFilter.length >= MIN_INSTITUTION_FILTER_LENGTH
      ),
    [
      billingRequest.id,
      bankAccountCountryCode,
      isSepaMandateFlow,
      institutionFilter,
    ]
  );

  const { data, isValidating } = useInstitutionListForBillingRequest(
    billingRequest.id,
    {
      country_code: bankAccountCountryCode as string,
      search: institutionFilter,
    },
    shouldFetchInstitutionsFromAPI
  );

  const getBanksInDescPopularityOrder = (
    popularBanks: Array<BankGB | BankDE | BankUS>
  ) => {
    const sortedBanksByPopularity: Array<InstitutionResource> = [];

    popularBanks?.forEach((bankName) => {
      const bank = availableInstitutions?.find(
        (institution) => bankName === institution.id
      );
      if (bank) {
        sortedBanksByPopularity.push(bank);
      }
    });

    return sortedBanksByPopularity;
  };

  const getPopularInstitutions = () => {
    // For sepa_core mandate flows, we fetch
    // popular institutions via InstitutionsInitialiser
    // using the `ids` query param; so no filtering is needed here.
    if (isSepaMandateFlow && usesOpenBankingGatewayAisAdapter(billingRequest)) {
      return availableInstitutions || [];
    }

    // the US fetches only popular institutions similarily to sepa_core,
    // but we want to enforce a non-alphabetical order
    if (mandateSchemeIs(billingRequest, AvailableDebitSchemeEnum.Ach)) {
      if (isUsingCompanyName(billingRequest)) {
        return getBanksInDescPopularityOrder(
          TopPopularBusinessBanks[CountryCodes.US]
        );
      }
      return getBanksInDescPopularityOrder(
        TopPopularPersonalBanks[CountryCodes.US]
      );
    }

    if (isUsingCompanyName(billingRequest)) {
      return getBanksInDescPopularityOrder(
        TopPopularBusinessBanks[CountryCodes.GB]
      );
    }
    return getBanksInDescPopularityOrder(
      TopPopularPersonalBanks[CountryCodes.GB]
    );
  };

  // If the payer goes through a sepa_core or ACH mandate flow,
  // institutions will be fetched from the API based on their search filter;
  // therefore the API response can be returned directly
  // (this is to avoid fetching all 1000+ supported institutions in the
  // InstitutionsInitialiser in production)
  //
  // Otherwise, we apply the search filter to the list of available institutions.
  const getInstitutionsFilteredBySearchQuery = () => {
    if (
      isSepaMandateFlow ||
      mandateSchemeIs(billingRequest, AvailableDebitSchemeEnum.Ach)
    ) {
      return shouldFetchInstitutionsFromAPI
        ? data?.institutions || []
        : getPopularInstitutions();
    }

    const resultList = fuse.search(institutionFilter).map(({ item }) => item);

    // Track that a new search was triggered
    sendEvent(TrackingEvents.SELECT_INSTITUTION_STEP_SEARCH, {
      search_query: institutionFilter,
      result_count: resultList?.length || 0,
    });

    return resultList;
  };

  const filteredList = useMemo(() => {
    // Get the initial list based on search state
    const getInitialList = () => {
      if (!institutionFilter.length) {
        return isSandbox ? availableInstitutions : getPopularInstitutions();
      }
      return getInstitutionsFilteredBySearchQuery();
    };

    // Filter out specific institutions if needed
    const removeSpecificInstitutions = (list: InstitutionResource[]) => {
      if (!isIBPv2) return list;
      // IBP V2 only supports RBDE institutions right now (all except Wise)
      return list.filter((inst) => inst.id !== "WISE_TRWIGB22");
    };

    const initialList = getInitialList();
    return removeSpecificInstitutions(initialList);
  }, [data, institutionFilter, availableInstitutions, isIBPv2]);

  const debouncedSetInstitutionFilter = debounce((searchQuery: string) => {
    setInstitutionFilter(searchQuery);
  }, 500);

  const handleOnChange = (searchQuery: string) => {
    debouncedSetInstitutionFilter(searchQuery);
  };

  // If we have an account on the billing request, we need to request that the
  // institution selected should match the currently configured bank account.
  // Otherwise the bank authorisation will fail.
  //
  // When we support AIS flows or can capture bank details from bank
  // authorisation, we will show this component before we ever capture bank
  // details.
  const accountNumberEnding =
    billingRequest?.resources?.customer_bank_account?.account_number_ending;

  //  Temporarily disabling
  const shouldShowIBPExperimentalVariant = false;

  const { i18n } = useLingui();

  const placeholderText = () => {
    if (isSepaMandateFlow) {
      return i18n._(
        t({
          id: "institution-selection.start-typing.placeholder",
          message: "Start typing to search for your bank",
        })
      );
    } else if (shouldShowIBPExperimentalVariant || isIBPv2) {
      // TODO: Translations
      return `Search for over 50+ banks`;
    } else {
      return i18n._(
        t({
          id: "institution-selection.start-typing.placeholder",
          message: "Start typing to search for your bank",
        })
      );
    }
  };

  const renderInstitutionList = () => {
    if (!filteredList?.length) {
      return <EmptyState />;
    }

    return (
      <InstitutionLinks
        filteredInstitutions={filteredList}
        onSelect={onSelect}
      />
    );
  };

  return (
    <>
      <Box css={isWithinDialog ? { touchAction: "none" } : null}>
        {isSepaMandateFlow ? (
          <>
            <Text
              weight={FontWeight.SemiBold}
              spaceBelow={1}
              size={5}
              layout="block"
            >
              <Trans id="institution-selection.bank-details">
                Your bank details
              </Trans>
            </Text>
            <Text weight={FontWeight.SemiBold} size={3}>
              <Trans id="institution-selection.search-bank">
                Search for your bank
              </Trans>
            </Text>
            <Space v={0.5} />
          </>
        ) : (
          customiseForScheme({
            scheme: leadScheme,
            key: "billing-request.bank-select.header",
            params: { billingRequest, runtimeMode },
          })
        )}

        {customiseForScheme({
          scheme: leadScheme,
          key: "billing-request.bank-select.payment-authorisation-notice",
          params: { billingRequest, runtimeMode },
        })}

        {accountNumberEnding &&
          customiseForScheme({
            scheme: leadScheme,
            key: "billing-request.bank-select.show-account-number-notice",
            params: { billingRequest },
          }) && (
            <P size={[2, 3]} spaceBelow={1}>
              <Trans id="institution-selection.same-as-account-ending.description-text">
                This must be the same as{" "}
                <strong>your account ending ******{accountNumberEnding}</strong>
                .
              </Trans>
            </P>
          )}
      </Box>
      <Box css={isWithinDialog ? { touchAction: "none" } : null}>
        <Layer mode="relative" top={0} zIndex={ZIndex.Dialog}>
          <Box bg={Color.White}>
            {/* TODO: responsively show 'or building society' on desktop */}
            <Input
              ref={searchBarRef}
              id="institution"
              placeholder={placeholderText()}
              rightAccessory={isIBPv2 ? <Icon name={Glyph.Search} /> : null}
              leftAccessory={!isIBPv2 ? <Icon name={Glyph.Search} /> : null}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e.target.value);
              }}
              data-testid="institution-search-input"
              css={{ fontSize: "14px" }} // TODO: preset via flux
            />
          </Box>
        </Layer>
        {shouldShowIBPExperimentalVariant &&
          // TODO: Translations
          institutionFilter?.length <= 1 && (
            <>
              <Space v={2} />
              <Text weight={FontWeight.SemiBold} size={4}>
                Popular banks
              </Text>
            </>
          )}
        <Box
          css={
            isIBPv2 && isMobile
              ? {
                  position: "relative",
                  height: isMobile ? "60vh" : "auto",
                  overflow: "hidden",
                }
              : undefined
          }
        >
          {isValidating ? <SearchingState /> : renderInstitutionList()}
        </Box>
      </Box>
    </>
  );
};

// extracted into a separate component to be able to re-use
// in scheme customisations without a lot of repeated code
// though in the future it would be good to have a default cutomisation
// and inherit all scheme customisations from it
export const InstitutionSelectionHeader = () => (
  <>
    <Text weight={FontWeight.SemiBold} size={6}>
      <Trans id="institution-selection.select-bank">
        Please select your bank
      </Trans>
    </Text>
    <Space v={1} />
  </>
);

export default InstitutionSelection;
