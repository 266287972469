import {
  CustomerBillingDetailObject,
  CustomerResource,
  AvailableDebitSchemeEnum,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { isAchMxExperimentMandate } from "src/common/utils";

import { CountryCodes, usRegionNames } from "../../common/country";

import { CustomerDetailsFields } from "./fields";
import {
  sharedPersonalFields,
  sharedContactFields,
  sharedAddressFields,
  addressZipCodeField,
} from "./shared";

// RFC 5322
export const EMAIL_VALIDATION_REGEX =
  // eslint-disable-next-line max-len, no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

// Common type used to render input fields within the forms
export interface FieldType {
  name: CustomerDetailsFields;
  label: string;
  placeholder?: string;
  instructions?: string;
  options?: object;
  required?: boolean;
  relevantToCompanyOnly?: boolean;
}

// The config map that should be used by the view
export interface CustomerFieldsConfig {
  personalFields: Record<string, FieldType>;
  contactFields: Record<string, FieldType>;
  addressFields: Record<string, FieldType>;
  schemeComplianceFields: Record<string, FieldType>;
}

// Map of schemes which require an address for the customer
const schemeRequiresAddress: Record<string, boolean> = {
  [AvailableDebitSchemeEnum.Ach]: true,
  [AvailableDebitSchemeEnum.Autogiro]: false,
  [AvailableDebitSchemeEnum.Bacs]: true,
  [AvailableDebitSchemeEnum.Becs]: false,
  [AvailableDebitSchemeEnum.BecsNz]: false,
  [AvailableDebitSchemeEnum.Betalingsservice]: false,
  [AvailableDebitSchemeEnum.Pad]: false,
  [AvailableDebitSchemeEnum.SepaCore]: true,
};

export const customerDetailsForSchemeAndCountry = (
  scheme: string,
  country: CountryCodes,
  billingRequest?: BillingRequestResource
): CustomerFieldsConfig => {
  return {
    personalFields: personalFields(country, billingRequest),
    contactFields: sharedContactFields(),
    addressFields: addressFieldsForSchemeAndCountry(
      scheme,
      country,
      billingRequest
    ),
    schemeComplianceFields: complianceFieldsForScheme(scheme),
  };
};

export const getAddressFieldTranslatedLabel = ({
  name,
  label,
}: FieldType): string => {
  switch (name) {
    case CustomerDetailsFields.AddressLine1:
      return i18n._(
        t({
          id: "fields-config.address-line1.label",
          message: "Billing address line 1",
        })
      );
    case CustomerDetailsFields.AddressLine2:
      return i18n._(
        t({
          id: "fields-config.address-line2.label",
          message: "Billing address line 2",
        })
      );
    case CustomerDetailsFields.City:
      return i18n._(
        t({
          id: "fields-config.city.label",
          message: "Town or City",
        })
      );
    case CustomerDetailsFields.PostalCode:
      if (label === "Zipcode") {
        return i18n._(
          t({
            id: "fields-config.zip-code.label",
            message: "Zipcode",
          })
        );
      }
      return i18n._(
        t({
          id: "fields-config.post-code.label",
          message: "Postcode",
        })
      );
    case CustomerDetailsFields.Region:
      return i18n._(
        t({
          id: "fields-config.region.label.us",
          message: "State/Territory",
        })
      );
    default:
      return label;
  }
};

export const getComplianceFieldsForSchemeTranslatedLabel = (
  { name, label }: FieldType,
  isCompany?: boolean
): string => {
  switch (name) {
    case CustomerDetailsFields.DanishIdentityNumber:
      return i18n._(
        t({
          id: "fields-config.danish-identity-number.label",
          message: "Your civic/company number (CPR/CVR)",
        })
      );
    case CustomerDetailsFields.SwedishIdentityNumber:
      if (isCompany) {
        return i18n._(
          t({
            id: "fields-config.swedish-identity-number.company.label",
            message: "Swedish company number",
          })
        );
      }
      return i18n._(
        t({
          id: "fields-config.swedish-identity-number.personal.label",
          message: "Swedish personal identity number",
        })
      );
    default:
      return label;
  }
};

export interface CustomerDetailsObject
  extends CustomerResource,
    CustomerBillingDetailObject {}

export const getRequiredErrorTranslated = (
  name: keyof CustomerDetailsObject
) => {
  switch (name) {
    case CustomerDetailsFields.CompanyName:
      return i18n._(
        t({
          id: "fields-config.company-name.required-error",
          message: "Enter your company name",
        })
      );
    case CustomerDetailsFields.Email:
      return i18n._(
        t({
          id: "fields-config.email.required-error",
          message: "Enter your email address",
        })
      );
    case CustomerDetailsFields.FamilyName:
      return i18n._(
        t({
          id: "fields-config.last-name.required-error",
          message: "Enter your last name",
        })
      );
    case CustomerDetailsFields.GivenName:
      return i18n._(
        t({
          id: "fields-config.first-name.required-error",
          message: "Enter your first name",
        })
      );
    case CustomerDetailsFields.AddressLine1:
      return i18n._(
        t({
          id: "fields-config.address-line1.required-error",
          message: "Enter your billing address line 1",
        })
      );
    case CustomerDetailsFields.AddressLine2:
      return i18n._(
        t({
          id: "fields-config.address-line2.required-error",
          message: "Enter your billing address line 2",
        })
      );
    case CustomerDetailsFields.City:
      return i18n._(
        t({
          id: "fields-config.city.required-error",
          message: "Enter your town or city",
        })
      );
    case CustomerDetailsFields.PostalCode:
      return i18n._(
        t({
          id: "fields-config.post-code.required-error",
          message: "Enter your postcode",
        })
      );
    case CustomerDetailsFields.Region:
      return i18n._(
        t({
          id: "fields-config.region.required-error",
          message: "Enter your state/territory",
        })
      );
    case CustomerDetailsFields.DanishIdentityNumber:
      return i18n._(
        t({
          id: "fields-config.danish-identity-number.required-error",
          message: "Enter your civic/company number (CPR/CVR)",
        })
      );
    case CustomerDetailsFields.SwedishIdentityNumber:
      return i18n._(
        t({
          id: "fields-config.swedish-identity-number.required-error",
          message:
            "Enter your Swedish personal identity number or company number",
        })
      );
    default:
      return i18n._(
        t({
          id: "fields-config.default.required-error",
          message: "Provide the required value",
        })
      );
  }
};

const personalFields = (
  country: CountryCodes,
  billingRequest?: BillingRequestResource
): Record<string, FieldType> => {
  if (isAchMxExperimentMandate(billingRequest, country)) {
    return {};
  }

  return sharedPersonalFields();
};

const addressFieldsForSchemeAndCountry = (
  scheme: string,
  country: CountryCodes,
  billingRequest?: BillingRequestResource
): Record<string, FieldType> => {
  // Not all schemes require an address
  if (!schemeRequiresAddress[scheme]) {
    return {};
  }

  if (isAchMxExperimentMandate(billingRequest, country)) {
    return { ...addressZipCodeField, ...regionalFields };
  }

  // The US requires region in the address validation (for some reason)
  // This may not be the case in the future
  if (country === CountryCodes.US) {
    if (
      billingRequest?.experimentation
        ?.is_eligible_for_ach_optional_address_experiments
    ) {
      return addressZipCodeField;
    }

    return { ...sharedAddressFields, ...regionalFields };
  }

  return sharedAddressFields;
};

const complianceFieldsForScheme = (
  scheme: string
): Record<string, FieldType> => {
  // We actually only have 2 schemes that require extra fields for compliance
  // purposes. If we get more we can change this.
  switch (scheme) {
    case AvailableDebitSchemeEnum.Betalingsservice: {
      return danishIdentityField;
    }
    case AvailableDebitSchemeEnum.Autogiro: {
      return swedishIdentityField;
    }
    default: {
      return {};
    }
  }
};

const regionalFields: Record<string, FieldType> = {
  [CustomerDetailsFields.Region]: {
    name: CustomerDetailsFields.Region,
    label: "State/Territory",
    placeholder: "",
    instructions: "",
    options: usRegionNames(i18n),
  },
  [CustomerDetailsFields.PostalCode]: {
    name: CustomerDetailsFields.PostalCode,
    label: "Zipcode",
    placeholder: "",
    instructions: "",
  },
};

const danishIdentityField: Record<string, FieldType> = {
  [CustomerDetailsFields.DanishIdentityNumber]: {
    name: CustomerDetailsFields.DanishIdentityNumber,
    label: "Your civic/company number (CPR/CVR)",
    placeholder: "",
    instructions: "",
  },
};

const swedishIdentityField: Record<string, FieldType> = {
  [CustomerDetailsFields.SwedishIdentityNumber]: {
    name: CustomerDetailsFields.SwedishIdentityNumber,
    label: "Swedish personal identity number or company number",
    relevantToCompanyOnly: true,
    placeholder: "",
    instructions: "",
  },
};
